.OtherReleases
  background-color: #040404
  .releases
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
    grid-gap: 0rem
    position: relative
    .release
      text-decoration: none
      color: #000000
      position: relative
      display: flex
      justify-content: center
      &.selected
        img
          opacity: 0.8
          filter: grayscale(0.1)
      img
        width: 100%
        opacity: 0.2
        filter: grayscale(0.8)
        cursor: pointer
      .name
        font-size: 0.75rem
        letter-spacing: 1px
        font-weight: 600
        position: absolute
        bottom: 1rem
        border-radius: 20px
        padding: 0rem 0.5rem 0 0.5rem
        opacity: 0
        text-transform: uppercase
      .controls
        position: absolute
        bottom: 1rem
        right: 1rem
        opacity: 1
        transform: scale(.7)
        > .PlayButton
          width: 64px
          height: 64px
          &:hover
            transform: scale(1.2)
      &:hover
        text-decoration: none
        color: #ffffff
        img
          transform: scale(0.96)
          border-radius: 0.25rem
          filter: grayscale(0)
          opacity: 0.8
        .name
          opacity: 1
          background-color: #000000
        .controls
          opacity: 1

@media (hover: none) //mobile

      

@media (max-width: 414px)
  .OtherReleases
    .releases
      display: grid
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr))
      grid-gap: 0rem
      position: relative
    .releaseView
      width: 100%
      height: 100%
      top: 0
      left: 0
      padding: 1rem
      .releaseViewContent
        width: 100%
        height: 100%
        border-radius: .5rem
        overflow: hidden
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2)

        img
          width: 100%
